<template>
  <td class="text-body-1 pre-wrap" :style="{textAlign: header.align ? header.align : 'center'}">
    <slot>{{ defaultValue }}</slot>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined
    },
    item: {
      default: undefined
    },
    value: {}
  },

  computed: {
    defaultValue() {
      if (this.value) {
        return this.value;
      } else if (this.item == undefined || this.header == undefined) {
        return "";
      } else {
        return this.item[this.header.value];
      }
    }
  }
};
</script>

<style scoped lang="css">
.pre-wrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
